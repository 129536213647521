import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Toolbar,
  AppBar,
  Button,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Logo from "../../../components/Logo";
import { useNavigate } from "react-router";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import NavBarRight from "../../HomeLayout/NavBarRight";
import RightOptions from "./RightOptions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import { AuthContext } from "../../../context/auth";
const TopBar = ({ onMobileNavOpen, isDashboard }) => {
  const navigate = useNavigate();

  const navItems = [
    {
      name: "Buy Crypto",
      href: "/",
      isMenu: true,

      menuItems: [
        {
          title: "Fast Trading",
          icon: "/assets/icons/topbar/stock-market.png",
          href: "/buy-crypto-fast-trading",
        },
        // {
        //   title: "Third Party",
        //   icon: "/assets/icons/topbar/peer-to-peer.png",
        //   href: "/",
        // },
        {
          title: "P2P",
          icon: "/assets/icons/topbar/network.png",
          href: "/buy-crypto-p2p",
        },
      ],
    },
    {
      name: "Market",
      href: `/trade/spot-trading?ETH/USDT`,
      // href: "/market-overview",
      isMenu: false,
    },
    // onClick={() =>
    //   navigate(
    //     `/trade/spot-trading?${
    //       item.symbol
    //     }/${selectedCurrency?.toUpperCase()}`
    //   )
    // }
    // {
    //   name: "Trade",
    //   href: "/",
    //   isMenu: true,

    //   menuItems: [
    //     {
    //       title: "Spot Trading",
    //       icon: "/assets/icons/topbar/chart.png",
    //       href: "/trade/spot-trading?TARAL/USDT",
    //     },
    //   ],
    // },

    {
      name: "Taral Earn",
      href: "/product-taral-earn",
      isMenu: false,
    },
    {
      name: "Taral Drop",
      href: "/product-taral-drop",
      isMenu: false,
    },
    // {
    //   name: "BNPL",
    //   href: "/product-bnpl",
    //   isMenu: false,
    // },
    {
      name: "More",
      href: "/",
      isMenu: true,

      menuItems: [
        {
          title: "Referral",
          icon: "/assets/icons/topbar/gift.png",
          href: "/referrals",
        },
        {
          title: "Reward",
          icon: "/assets/icons/topbar/network-more.png",
          href: "/rewards",
        },
      ],
    },
  ];
  const [openDrawer, setOpenDrawer] = useState(false);
  // const auth = useContext(AuthContext);
  // const { userLoggedIn } = auth;
  // console.log("userLoggedIn--", userLoggedIn);
  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? colors.bgDark : colors.white,
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
    borderRadius: "0px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar component="nav" sx={themeColors}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isDashboard && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onMobileNavOpen}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Logo
                style={{
                  width: "30%",
                  minWidth: "100px",
                  maxWidth: "150px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
              <Box
                sx={{
                  display: { xs: "none", sm: "none", lg: "flex" },
                  marginLeft: "80px",
                }}
              >
                {navItems &&
                  navItems.map((item, i) => {
                    return (
                      <DrawerTopButton
                        item={item}
                        key={`navBtn${i}`}
                        navigate={navigate}
                      />
                    );
                  })}
              </Box>
            </Box>

            <RightOptions
              themeSettings={themeSettings}
              sx={{
                display: { lg: "flex", sm: "none", md: "none", xs: "none" },
                alignItems: "center",
              }}
            />

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ display: { sm: "block", lg: "none" } }}
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <NavBarRight
              onMobileClose={() => setOpenDrawer(false)}
              openMobile={openDrawer}
              themeSettings={themeSettings}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const DrawerTopButton = ({ navigate, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {item?.isLogin ? (
        <Button
          color="secondary"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      ) : (
        <Button
          color="secondary"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            if (item.isMenu) {
              handleClick(e);
            } else {
              navigate(item.href);
            }
          }}
          sx={{
            textTransform: "none",
            margin: "0px 4px",
          }}
          endIcon={
            item.isMenu && (
              <>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} </>
            )
          }
        >
          {item.name}
        </Button>
      )}

      {open && (
        <>
          {item.isMenu && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {item &&
                item.menuItems &&
                item.menuItems.map((x, i) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(x.href);
                      }}
                      key={`topMenuSub${i}`}
                    >
                      <img src={x.icon} alt="" />
                      &nbsp;&nbsp;{x.title}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
        </>
      )}
    </React.Fragment>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
