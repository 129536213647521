import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router";
import Logo from "../../components/Logo";
import { colors } from "../../assets/colors";
import ThemeContext from "../../context/themeContext";
import FlexButton from "../../components/FlexButton";

export default function TopBar() {
  const themeSettings = React.useContext(ThemeContext);
  const navigate = useNavigate();

  const themeColors = {
    backgroundColor:
      themeSettings.settings.theme === "DARK" ? colors.bgDark : colors.white,
    color:
      colors[
        themeSettings.settings.theme === "DARK"
          ? colors.white
          : colors.darkPrimary
      ],
    boxShadow: "none",
    borderRadius: "0px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={themeColors}>
        <Toolbar>
          <Box className="displaySpacebetween" width="100%">
            <Logo
              style={{
                width: "50%",
                minWidth: "100px",
                maxWidth: "150px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />
            <Box className="displayEnd">
              <FlexButton
                title="Login"
                color="secondary"
                disabled={false}
                fullWidth={false}
                sx={{
                  marginRight: "10px",
                }}
                onClick={() => navigate("/login")}
              />
              <FlexButton
                title="Sign Up"
                color="primary"
                disabled={false}
                fullWidth={false}
                onClick={() => navigate("/signup")}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

TopBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
