import {
  TextField,
  FormControl,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import FlexButton from "./FlexButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function DynamicFormField({
  validationScema,
  initialValues,
  onSubmit,
  fromData,
  btnTitle,
  btnDisabled,
}) {
  return (
    <Formik
      initialValues={initialValues}
      initialStatus={{
        success: false,
        successMsg: "",
      }}
      validationSchema={validationScema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          {fromData &&
            fromData.map((item) => {
              return (
                <FormTextFiield
                  key={`formFi${item.id}`}
                  item={item}
                  values={values}
                  touched={touched}
                  errors={errors}
                  btnTitle={btnTitle}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              );
            })}

          <FlexButton
            title={btnTitle}
            type="submit"
            color="primary"
            fullWidth={true}
            disabled={btnDisabled}
          />
        </Form>
      )}
    </Formik>
  );
}

const FormTextFiield = ({
  item,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  btnTitle,
}) => {
  const [isHide, setIsHide] = useState(true);
  const [checkPasswordStrength, setCheckPasswordStrength] = useState({
    isSpecial: false,
    isStrongLength: false,
    isDigit: false,
    isUpperCase: false,
    isLowerCase: false,
  });

  //function to check user's password strength
  function passwordFunction(value) {
    setCheckPasswordStrength({
      isSpecial: value.match(/[!@#$%^&*(),.?":{}|<>]/),
      isStrongLength: value.match("^(.{8,})"),
      isDigit: value.match("^(?=.*?[0-9])"),
      isUpperCase: value.match("^(?=.*?[A-Z])"),
      isLowerCase: value.match("^(?=.*?[a-z])"),
    });
  }

  return (
    <FormControl fullWidth key={item.id} sx={{ marginBottom: "12px" }}>
      <Typography variant="body1">{item.title}</Typography>
      {item.type === "select" && (
        <Select
          fullWidth
          name={item.id}
          value={values[item.id]}
          error={Boolean(touched[item.id] && errors[item.id])}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          placeholder={item.placeHolder}
        >
          {item.selectData &&
            item.selectData.map((x, i) => {
              return (
                <MenuItem value={x.value} key={`${x.value}-${i}-${item.id}`}>
                  {x.label}
                </MenuItem>
              );
            })}
        </Select>
      )}
      {item.type !== "select" && (
        <TextField
          sx={{
            marginTop: "5px",
            "&.MuiOutlinedInput-notchedOutline": {
              borderColor: "none",
            },
          }}
          name={item.id}
          type={isHide ? item.type : "text"}
          rows={item.title === "Message" ? 5 : null}
          multiline={item.title === "Message" ? true : false}
          // inputProps={{ maxLength: 60 }}
          value={values[item.id]}
          error={Boolean(touched[item.id] && errors[item.id])}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            if (
              item.id === "password" &&
              (btnTitle === "Sign up" || btnTitle === "Change")
            ) {
              passwordFunction(e.target.value);
            }
          }}
          id={item.id}
          placeholder={item.placeHolder}
          fullWidth
          InputProps={
            item.type === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsHide(!isHide)}>
                        {isHide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      )}

      <FormHelperText error>
        {touched[item.id] && errors[item.id]}
      </FormHelperText>
      {values["password"] !== "" &&
        item.id === "password" &&
        (btnTitle === "Sign up" || btnTitle === "Change") && (
          <Box mt={0.5}>
            <Typography variant="body1">Your password must contain:</Typography>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isStrongLength ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isStrongLength ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}{" "}
              <Typography variant="body1">At least 10 characters</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isLowerCase ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isLowerCase ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">Lower case letter (a-z)</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isUpperCase ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isUpperCase ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">Upper case letter (A-Z)</Typography>
            </Box>
            <Box
              className={`displayStart ${
                checkPasswordStrength.isSpecial ? "green" : "waiting"
              } icocmargin`}
            >
              {checkPasswordStrength && checkPasswordStrength.isSpecial ? (
                <CheckIcon fontSize="15px" />
              ) : (
                <FiberManualRecordIcon fontSize="15px" />
              )}
              <Typography variant="body1">
                {`Special characters (!@#$%ˆ&*)`}
              </Typography>
            </Box>
          </Box>
        )}
    </FormControl>
  );
};
