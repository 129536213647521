import { Box, Divider, IconButton, Tooltip, Badge } from "@mui/material";
import { React, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ThemeSwitchButton from "../../../components/themeSwitchButton";
import NotificationModal from "../../../components/modals/NotificationModal";
import { AuthContext } from "../../../context/auth";
import { FlexLayerContext } from "../../../context/flexLayer";

export default function RightOptions({ themeSettings, sx }) {
  const { userLoggedIn } = useContext(AuthContext);
  const { notificationData } = useContext(FlexLayerContext);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const notificationModal = useMemo(() => {
    return (
      <NotificationModal
        open={isActive}
        close={() => setIsActive(false)}
        data={notificationData?.data}
      />
    );
  }, [isActive]);

  return (
    <Box sx={sx}>
      <Tooltip title="Profile">
        <IconButton onClick={() => navigate("/user-profile")}>
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Wallet">
        <IconButton onClick={() => navigate("/wallet")}>
          <AccountBalanceWalletIcon />
        </IconButton>
      </Tooltip>
      {userLoggedIn && (
        <Tooltip title="Notifications">
          <IconButton
            style={{ marginRight: "10px" }}
            onClick={() => setIsActive(true)}
          >
            <Badge
              badgeContent={notificationData && notificationData?.unReadCount}
              color="secondary"
            >
              <NotificationsIcon color="secondary" />
            </Badge>
          </IconButton>
        </Tooltip>
      )}

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor:
            themeSettings.settings.theme === "DARK" ? "#b9b3b3" : "#fff",
          margin: "0px 4px",
        }}
      />
      <ThemeSwitchButton />
      {isActive && notificationModal}
    </Box>
  );
}
