//staging server
export const baseURL = "https://stgapi-exchange.tarality.io";
// export const baseURL = "http://localhost:5013";
export const socket_web_url = "wss://stgwebsocket.tarality.io";
export const socket_url = "https://socket.tarality.io";
export const notification_socket_url = "https://stgapi-exchange.tarality.io";

// production server
// export const baseURL = "https://api.tarality.io";
// export const socket_url = "https://prodsocket.tarality.io";
// export const socket_web_url = "wss://websocket.tarality.io";
// export const notification_socket_url = "https://api.tarality.io";
export const orderbookJavaUrl = "https://orderbook.tarality.io";
export const staticContentURL =
  "https://api.tarality.online/api/v2/page/getPagelist/a";

let version = "api"; //v0
let version_v1 = "api/v1"; //v0
let kyc = `kyc`;
let user = `${version_v1}/user`;
let plan = `${version_v1}/user/plan`;
let cbip = `${version_v1}/cbip`;
let graph = `${version_v1}/graph`;

let bank = `${version_v1}/bank`;
let lockfund = `${version_v1}/lockfund`;
let reward = `${version_v1}/reward`;
let help = `${version_v1}/help`;
let notification = `${version_v1}/notification`;
let p2p = `${version_v1}/p2p`;


const apiConfigs = {
  socialLogin: `${baseURL}/${version}/login-social`,
  userProfile: `${baseURL}/${version}/get-profile-info`,
  userWallet: `${baseURL}/${version}/get-wallets-new`,
  signUp: `${baseURL}/${version}/register-user`,
  userLogin: `${baseURL}/${version}/login-new`,
  verifyOTP: `${baseURL}/${version}/verifyOTP`,
  getGoogleAuth: `${baseURL}/${version}/get-auth-google`,
  resetPassword: `${baseURL}/${version}/reset-password`,
  freezeAccount: `${baseURL}/${version}/freeze-acount`,
  forgotPassword: `${baseURL}/${version}/forget-password`,
  verifyOTPForgot: `${baseURL}/${version}/varifie/forget-password`,
  changePassword: `${baseURL}/${version}/set-password`,
  gerReferralCode: `${baseURL}/${version}/user/get-referals`,
  updateWallet: `${baseURL}/${version}/update-wallet`,

  //----2FFA----//
  get2FaQR: `${baseURL}/${version}/set-auth-google`,
  enable2FA: `${baseURL}/${version}/set-auth-google-otp`,
  sendMobileOTP: `${baseURL}/${version}/send-mobile-varification-otp`,
  verifyMobileOTP: `${baseURL}/${version}/varifie/mobile`,

  //----kyc----//
  updateKycProfile: `${baseURL}/${kyc}/update-profile`,
  checkStatus: `${baseURL}/${version_v1}/${kyc}/check-status`,
  uploadKYC: `${baseURL}/${version_v1}/${kyc}/upload-kyc`,
  verifyPan: `${baseURL}/${version_v1}/${kyc}/verify-pan`,
  validateAadhar: `${baseURL}/${version_v1}/${kyc}/validate-aadhar`,
  verifyAadhar: `${baseURL}/${version_v1}/${kyc}/verify-adhar`,
  submitKYC: `${baseURL}/${version_v1}/${kyc}/submit-kyc`,
  uploadSelfie: `${baseURL}/${version_v1}/${kyc}/upload-selfie`,

  //----crypto deposit/withdraw----//
  coinList: `${baseURL}/${version}/getallsymbol`,
  getQrCode: `${baseURL}/${version}/get-blockchain-data`,
  cryptoWithdrawHistory: `${baseURL}/${version}/withdraw_history`,
  cryptoDepositHistory: `${baseURL}/${version}/deposit_history`,
  generateWithdrawOTP: `${baseURL}/${version}/get-withdraw`,
  verifyMobile: `${baseURL}/${version}/varifie/mobile-Withdraw`,
  verifyEmail: `${baseURL}/${version}/varifie/email-Withdraw`,
  balanceDetails: `${baseURL}/${user}/balanceDetails`,

  //----crypto banking----//
  activeAssetsList: `${baseURL}/${cbip}/activeAssetsList`,
  investBalance: `${baseURL}/${cbip}/investBalance`,
  investHistory: `${baseURL}/${cbip}/investHistory`,
  withdrawCryptoBankingFund: `${baseURL}/${cbip}/withdrawCryptoBankingFund`,
  applyLoan: `${baseURL}/${cbip}/applyLoan`,
  payLoan: `${baseURL}/${cbip}/payLoan`,
  paymentHistory: `${baseURL}/${cbip}/paymentHistory`,
  addSpotFund: `${baseURL}/${user}/addSpotFund`,
  withdrawSpotFund: `${baseURL}/${user}/withdrawSpotFund`,
  spotHistory: `${baseURL}/${user}/spotHistory`,

  //----trade----//
  fastTradeOrder: `${baseURL}/${version}/fast-trade-order`,
  fastTradeHistory: `${baseURL}/${version}/fast-trade-history`,

  //----graph----//
  graphData: `${baseURL}/${graph}/cmc_graph_data`,

  // ----Taral Earn/ Drop----//
  getPlanList: `${baseURL}/${plan}/getPlanList`,
  participate: `${baseURL}/${plan}/participate`,
  detailsParticipant: `${baseURL}/${plan}/detailsParticipant`,
  topParticipants: `${baseURL}/${plan}/topParticipants`,
  userPlanDetails: `${baseURL}/${plan}/userPlanDetails`,
  listHistory: `${baseURL}/${plan}/listHistory`,
  planInvestmentHistory: `${baseURL}/${plan}/planInvestmentHistory`,
  earnDropHistory: `${baseURL}/${plan}/earnDropHistory`,

  //----order-book----//
  buyOrder: `${baseURL}/${version}/buy-order1`,
  sellOrder: `${baseURL}/${version}/sell-order1`,
  executeOrder: `${baseURL}/${version}/execute-orders`,
  pendingOrders: `${baseURL}/${version}/pending-order`,
  completeOrders: `${baseURL}/${version}/complete-order`,
  cancelOrder: `${baseURL}/${version}/cancle-order`,
  getpairedCurrency: `${baseURL}/${version}/getpairedCurrency`,
  getuserrefreshSpotFund: `${baseURL}/${user}/refreshSpotFund`,

  //--subscriber--//

  subscribe: `${baseURL}/${version_v1}/subscriber/createSubscriber`,

  // --- anouncement---//
  announcmentList: `${baseURL}/${version_v1}/announcement/getAnnouncementList`,

  // --- Notifications---//
  notification: `${baseURL}/${version_v1}/notification/getNotification`,
  del: `${baseURL}/${version_v1}/notification/deleteNotification`,

  // ---Add Bank ----//
  bankStatus: `${baseURL}/${bank}/viewBank`,
  bankStatusP2P: `${baseURL}/${bank}/viewp2pBank`,
  addBank: `${baseURL}/${bank}/addBank`,
  addp2pBank: `${baseURL}/${bank}/addp2pBank`,
  bankList: `${baseURL}/${version}/banking/get-bank`,
  editBank: `${baseURL}/${bank}/editBank`,
  editP2PBank: `${baseURL}/${bank}/editP2PBank`,
  deleteBank: `${baseURL}/${bank}/deleteBank`,

  //----inr-deposit-withdraw----//
  getAdminBank: `${baseURL}/${bank}/getAdminBank`,
  uploadImageFile: `${baseURL}/${user}/uploadImageFile`,
  depositRequest: `${baseURL}/${bank}/depositRequest`,
  withdrawRequest: `${baseURL}/${bank}/withdrawRequest`,

  //----locked balances----//
  getLockFundList: `${baseURL}/${lockfund}/getLockFundList`,
  viewLockFund: `${baseURL}/${lockfund}/viewLockFund`,
  getLockWalletList: `${baseURL}/${lockfund}/getLockWalletList`,

  //----user referral----//
  getReferralRewards: `${baseURL}/${user}/getReferralRewards`,
  deleteAccount: `${baseURL}/${user}/deleteAccount`,

  //----reward----//
  getRewardScratch: `${baseURL}/${reward}/getRewardScratch`,
  scratchCard: `${baseURL}/${reward}/scratchCard`,
  getRewardHistory: `${baseURL}/${reward}/getRewardHistory`,

  //----help and support----//
  categoryList: `${baseURL}/${help}/categoryList`,
  raiseTicket: `${baseURL}/${help}/raiseTicket`,
  queryTicketList: `${baseURL}/${help}/queryTicketList`,
  ticketDetails: `${baseURL}/${help}/ticketDetails`,
  reply: `${baseURL}/${help}/reply`,
  queryRaise: `${baseURL}/${help}/queryRaise`,
  submitFeedback: `${baseURL}/${help}/submitFeedback`,

  //----notifiaction----//
  readNotification: `${baseURL}/${notification}/readNotification`,
  notification: `${baseURL}/${notification}/notification`,

  //----payment options----//
  moduleList: `${baseURL}/${user}/moduleList`,
  paymentCoinList: `${baseURL}/${user}/paymentCoinList`,

  //----orderbook-----//
  accounts: `${orderbookJavaUrl}/${version}/accounts`,
  setAccessToken: `${orderbookJavaUrl}/${version}/users/setAccessToken`,
  products: `${orderbookJavaUrl}/${version}/products`,
  accessToken: `${orderbookJavaUrl}/${version}/users/accessToken`,

   //----p2p ----//
 fetchp2pOrder: `${baseURL}/${p2p}/p2p-order-list`,
 buy_sell_p2pOrder: `${baseURL}/${p2p}/sell-p2p-order`,
 initiate_P2P_order:`${baseURL}/${p2p}/initiate-order`,
 p2p_order_history:`${baseURL}/${p2p}/p2p-order-history`,
 p2p_order_token_initiate:`${baseURL}/${p2p}/p2p-order-token-initiate`,
 p2p_order_actions:`${baseURL}/${p2p}/p2p-order-actions`,
 p2p_set_chat:`${baseURL}/${version}/set-chat`,
 p2p_get_chat:`${baseURL}/${version}/get-chat`,
 addP2PFund: `${baseURL}/${user}/addP2pFund`,
 withdrawP2pFund: `${baseURL}/${user}/withdrawP2pFund`,
 p2pHistory: `${baseURL}/${user}/p2pHistory`,



};

export default apiConfigs;
