export const colors = {
  darkPrimary: "#1D1D1D",
  darkSecondary: "#2C2C2C",
  bgDark: "#272727",
  white: "#fff",
  black: "#000",
  darkGrey: "#404040",
  gold: "#ffc107",
  btnGold: "#FFCA65",
  red: "#FB3E3E",
  green: "#01bc8d",
};
