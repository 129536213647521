import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import DynamicFormField from "../DynamicFormField";
import axios from "axios";
import { apiRouterCall } from "../../api/services";
import ScreenLoader from "../screenLoader";
import SuccessModal from "./SuccessModal";

export default function SupportModal({ open, close }) {
  const [subjectsList, setSubjectsList] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  //function to get the support subjects list
  const getSupportSubjectListHandler = async (source) => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "categoryList",
        source: source,
      });
      if (response.data.responseCode === 200) {
        setSubjectsList(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getSupportSubjectListHandler(source);
    return () => {
      source.cancel();
    };
  }, []);

  //form fields with there relevant id that should match with the formik inital form schema
  const fromData = [
    {
      id: "name",
      title: " Name",
      placeHolder: "Enter your first name",
      type: "text",
    },
    {
      id: "emailAddress",
      title: "Email Address",
      placeHolder: "Enter your email address",
      type: "email",
    },
    {
      id: "supportSubject",
      title: "Support Subject",
      placeHolder: "Select the support subject...",
      type: "select",
      selectData:
        subjectsList &&
        subjectsList.map((x) => {
          return {
            label: x?.categoryType,
            value: x?._id,
          };
        }),
    },
    {
      id: "contact",
      title: "Mobile Number",
      placeHolder: "Enter your mobile number",
      type: "mobile",
    },
    {
      id: "message",
      title: "Message",
      placeHolder: "Enter your query",
      type: "message",
    },
  ];

  //Validation schema using Yup
  const validationScema = Yup.object().shape({
    name: Yup.string().required("Please enter your first name."),
    emailAddress: Yup.string()
      .required("Please enter your email address.")
      .email("Please enter a valid email address."),
    contact: Yup.string()
      .required("Please enter your mobile number.")
      .max(10, "Please enter valid mobile number"),
    message: Yup.string().required("Please enter your query."),
    supportSubject: Yup.string().required("Please select subject query."),
  });

  //formik form inital schema
  const initialValues = {
    name: "",
    emailAddress: "",
    contact: "",
    message: "",
    supportSubject: "",
  };

  //function to raise a support ticket
  const submitHandler = async (values) => {
    try {
      setIsProcessing(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "queryRaise",
        data: {
          categoryId: values?.supportSubject,
          email: values?.emailAddress,
          mobileNumber: values?.contact,
          name: values?.name,
          message: values?.message,
        },
      });
      if (response.data.responseCode === 200) {
        setIsSuccess(true);
      }
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle position="relative">
          <Typography variant="h3" textAlign="center">
            Need Help?
          </Typography>
          <Box sx={{ position: "absolute", top: "8px", right: "8px" }}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: "30px" }}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box>
                <img src="/assets/images/Questions.png" alt="" width="100%" />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <DynamicFormField
                initialValues={initialValues}
                validationScema={validationScema}
                fromData={fromData}
                btnTitle="Submit"
                btnDisabled={false}
                onSubmit={(values) => submitHandler(values)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
      {isSuccess && (
        <SuccessModal
          open={isSuccess}
          title={"Message Sent!"}
          close={() => {
            close();
            setIsSuccess(false);
          }}
          description={`Your query has been sent successfully! Our team will get back to shortly.`}
        />
      )}
    </Fragment>
  );
}
