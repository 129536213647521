import { lazy } from "react";
import { Navigate } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/home")),
  },

  //--Rewards --//

  {
    exact: true,
    guard: true,
    path: "/rewards",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/rewards")),
  },

  //---Announcements---//
  {
    exact: true,
    path: "/resources/:id",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/announcement/PreviewPage.jsx")
    ),
  },

  //user-profile
  {
    exact: true,
    guard: true,
    path: "/user-profile",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/referrals",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/referrals")),
  },

  //user-kyc-steps
  {
    exact: true,
    guard: true,
    path: "/user-kyc-info",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kyc")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-account-settings",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/settings")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/about")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/terms")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/policy")),
  },
  {
    exact: true,
    guard: true,
    path: "/business-contact",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/contact")),
  },

  //user-authentication------------------------------------------------------
  {
    exact: true,
    path: "/login",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Login")),
  },
  {
    exact: true,
    path: "/signup",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/SingUp")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ForgetPassword.jsx")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ResetPassword.jsx")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Verify")),
  },
  //user-authentication------------------------------------------------------

  {
    exact: true,
    path: "/market-overview",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/market/Overview")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/overview")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-history",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/wallet/overview/WalletHistory.jsx")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-deposit",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/cryptoDeposit")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-withdraw",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/cryptoWithdraw")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-transfer",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/transfer")),
  },

  //taral-product------------------------------------------------------
  {
    exact: true,
    guard: true,
    path: "/product-taral-earn",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/taralEarn")),
  },

  {
    exact: true,
    guard: true,
    path: "/product-taral-earn-info",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/poducts/taralEarn/TaralearnInfo")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/product-taral-banking-transfer",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/transfer")),
  },

  {
    exact: true,
    guard: true,
    path: "/product-spot-banking-transfer",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/transfer")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-p2p-transfer",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/transfer")),
  },

  {
    exact: true,
    guard: true,
    path: "/product-taral-drop",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/taralDrop")),
  },

  //taral-product------------------------------------------------------
  {
    exact: true,
    path: "/buy-crypto-p2p",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/p2p/index")),
  },

  {
    exact: true,
    path: "/buy-p2p",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/p2p/p2pBuy")),
  },
  {
    exact: true,
    path: "/p2p_order_histroy",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/p2p/p2pOrderHistory")),
  },

  {
    exact: true,
    guard: true,
    path: "/buy-crypto-fast-trading",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/fastTrade")),
  },
  {
    path: "/trade/spot-trading",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/spotTrade")),
  },

  //support section
  {
    exact: true,
    guard: true,
    path: "/support/raise-tickets",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/support")),
  },

  {
    exact: true,
    path: "*",
    component: lazy(() => import("../views/errors/NotFound")),
  },

  {
    component: () => <Navigate to="/404" />,
  },
];
