import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { notification_socket_url, socket_url, socket_web_url } from "../api";
import { socket_access_token } from "../contants";
import { apiRouterCall } from "../api/services";

export const FlexLayerContext = createContext();

export default function FlexLayerProvider(props) {
  const [coinListData, setCoinListData] = useState([]);
  const [sellOrders, setSellOrders] = useState({});
  const [buyOrders, setBuyOrders] = useState({});
  const [tradeHistory, setTradeHistory] = useState([]);
  const [webSocketCoinList, setWebSocketCoinList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("usdt");
  const [serverTime, setServerTime] = useState("");
  const [currency, setCurrency] = useState([]);
  const [notificationData, setNotificationData] = useState({});
  const [orderBookData, setOrderBookData] = useState({});

  /*
  function to create a socket connection once 
  the user lands to the application 
  and also diconnect the same connection once the user leaves
  */

  useEffect(() => {
    const socket = io(notification_socket_url);
    socket.on("connect", () => {
      console.log("Connected to the server---notifications!");
    });
    // Emitter to get the cup with the user ID
    socket.emit("userConnected", {
      userId: window.sessionStorage.getItem("user_id"),
    });
    // Emitter to get the cup with the user ID
    socket.emit("notification", {
      userId: window.sessionStorage.getItem("user_id"),
    });
    //emitter to get the cup
    socket.on("notification", (data) => {
      // console.log("notification--", data);
      setNotificationData(data);
    });

    // Clean up socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [window.sessionStorage.getItem("user_id")]);

  useEffect(() => {
    const socket = io(socket_url, {
      auth: {
        token: socket_access_token,
      },
    });

    socket.on("connect", () => {
      console.log("Connected to the server!");
    });

    //emitter to get the cup
    socket.on("cmc_updated", (data) => {
      // console.log("data-as--cmc--", data);
      setCoinListData(data);
    });

    socket.on("buy_order_updated", (data) => {
      setBuyOrders(data);
    });

    socket.on("sell_order_updated", (data) => {
      setSellOrders(data);
    });

    socket.on("order_history_updated", (data) => {
      setTradeHistory(data);
    });

    socket.on("server_time", (data) => {
      setServerTime(data);
    });

    // Clean up socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const socket = new WebSocket(socket_web_url); // Replace with your WebSocket server URL

    socket.onopen = () => {
      //* Request First*//
      socket.send(
        JSON.stringify({
          request_type: "coin_list",
          currency_type: selectedCurrency,
        })
      );
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log("data--as--aashu sir", data);
      setWebSocketCoinList(data?.data);
    };

    socket.onclose = () => {
      // console.log("WebSocket disconnected");
      // You may handle reconnection logic here if needed
    };

    return () => {
      socket.close(); // Close the WebSocket connection on component unmount
    };
  }, [selectedCurrency]);

  //function to get the active pair currency
  const getpairCurrencyListHandler = async () => {
    try {
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "getpairedCurrency",
        data: {
          user_id: window.sessionStorage.getItem("user_id"),
        },
      });

      if (response.status === 200) {
        setCurrency(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpairCurrencyListHandler();

    return () => {};
  }, []);

  let data = {
    currency,
    buyOrders,
    serverTime,
    sellOrders,
    coinListData,
    tradeHistory,
    selectedCurrency,
    webSocketCoinList,
    notificationData,
    orderBookData,
    setSelectedCurrency: (data) => setSelectedCurrency(data),
  };

  return (
    <FlexLayerContext.Provider value={data}>
      {props.children}
    </FlexLayerContext.Provider>
  );
}
